import { Link } from "gatsby"
import { graphql } from "gatsby"
import React from "react"

import Layout from "../components/layout"
import Seo from "../components/seo"
const Post = ({ location,data }) => {
  // 記事ページ作成
  const htmlcontents = data.allContentfulPost.edges

  return (
    <>
      {htmlcontents.map(htmlcontent => {
        return (
          <>
          <Layout location={location} title={htmlcontent.node.title}>
            {htmlcontent.node.thumbnail ?
              <Seo title={htmlcontent.node.title} description={htmlcontent.node.description.description} thumbnail={htmlcontent.node.thumbnail.file.url} /> :
              <Seo title={htmlcontent.node.title} description={htmlcontent.node.description.description} />
            }
            { htmlcontent.node.image &&
              <picture>
                <source type="image/webp" srcSet={ htmlcontent.node.image.gatsbyImageData.images.sources[0].srcSet} />
                <img src={ htmlcontent.node.image.file.url} className="post-image" alt="post-cover"></img>
              </picture>
            }
          <div className="post-header">
            {htmlcontent.node.category === "news" &&
              <>
                <div className="post-header-news">
                  <p>{htmlcontent.node.publishDate} <span>お知らせ</span></p>
                </div>
              </>
            }
            <h1>{htmlcontent.node.title}</h1>
          </div>
            <div dangerouslySetInnerHTML={{ __html: htmlcontent.node.body.childMarkdownRemark.html }} className="post-body" />
            {htmlcontent.node.category === "news" && 
              <Link to="/category/news" className="post-footer-news">
              <div className="post-footer-news">
                一覧に戻る
              </div>
              </Link>
            }
            </Layout>
            </>
          )
        })}
    </>
  )
}

export default Post

export const pageQuery = graphql`
  query ($slug: String){
    allContentfulPost (
      limit:1
      filter: {slug: {eq: $slug}}
      sort: {fields: publishDate, order: DESC}
    ) {
      edges {
        node {
          title
          image {
            gatsbyImageData(layout: FULL_WIDTH, quality: 100, formats: WEBP)
            file {
              url
            }
          }
          body {
            childMarkdownRemark {
              html
            }
          }
          description {
            description
          }
          category
          publishDate(locale: "ja-JP", formatString: "YYYY/MM/DD")
          thumbnail {
            file {
              url
            }
          }
        }
      }
    }
  }
`